<template>
  <div :style="CSS" :class="CSSClasses" class="pagination" ref="pagination" v-loading='loading'>
    <div class="pagination-wrapper" v-if="isCircle">
      <i class="el-icon-arrow-left btn btn--prev" @click="prev"></i>
      <div class="pagination-container">
        <div class="slider-line" ref="sliderLine" :style="`transform: translateX(${widthSliderline}px);`">
          <div
            v-for="(pageNumber, index) of circles"
            :key="index"
            class="little-dot"
            :class="{ active: currentPage === pageNumber }"
          >
          </div>
        </div>
      </div>
      <i class="el-icon-arrow-right btn btn--next" @click="next"></i>
    </div>
    <el-pagination
      ref="elPagination"
      v-else
      small
      :page-size="limit"
      :layout="layout"
      @current-change="currentChange"
      :total="total"
    >
    </el-pagination>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import ModelMixin from '@/components/InterfaceEditor/components/ModelMixin'
import { deepCompare } from '@/helpers'
export default {
  mixins: [mixin, ModelMixin],
  name: 'pagination',
  props: {
    value: {
      frozen: true
    },
    limit: {
      frozen: true
    },
    replicationGuid: {
      frozen: true
    },
    settings: {
      frozen: true
    },
    replicationFilter: {
      type: Object,
      frozen: true
    },
    isTotal: {
      type: Boolean,
      description: 'count',
      default: false
    },
    isCircle: {
      type: Boolean,
      description: 'dot',
      default: false
    }
  },
  data () {
    return {
      total: null,
      currentPage: 1,
      widthSliderline: 0,
      guid: '',
      oldFilter: {},
      loading: false,
      queryParameters: null,
      oldQueryParameters: null
    }
  },
  watch: {
    replicationFilter: {
      handler (val, oldVal) {
        if (this.guid) {
          const blockReplicationWithoutLimitOffser = val[this.guid]?.where
          this.queryParameters = val[this.guid]?.query_parameters || null
          // Если фильтр поменялись, кроме limit,state_id и offset, То пересчитать кол-во записей и сбросить пагинацию на единицу
          // Если queryParameters поменялись
          if (!deepCompare(blockReplicationWithoutLimitOffser, this.oldFilter) || !deepCompare(this.queryParameters, this.oldQueryParameters)) {
            this.oldFilter = blockReplicationWithoutLimitOffser
            this.oldQueryParameters = this.queryParameters
            let promisesGetNumbersOfEntries = (ms = 0) => new Promise((resolve, reject) => setTimeout(async () => {
              this.currentPage = 1
              try {
                if (!this.isCircle) {
                  this.$refs.elPagination.internalCurrentPage = 1
                }
                await this.setNumbersOfEntries(this.settings)
                resolve()
              } catch (error) {
                reject(error)
              }
            }, ms))
            promisesGetNumbersOfEntries()
              .catch((error) => {
                console.error('Ошибка получения кол-ва записей в пагинации', error)
              })
              .finally(() => {
                this.loading = false
              })
          }
        }
      },
      deep: true
    }
  },
  computed: {
    layout () {
      if (this.settings?.sourceId && this.isTotal) {
        return 'total, prev, pager, next'
      } else {
        return 'prev, pager, next'
      }
    },
    circles () {
      if (Math.ceil(this.total / this.limit) > 50) {
        return 51
      }
      return Math.ceil(this.total / this.limit)
    }
  },
  methods: {
    next () {
      // Если последняя точка
      if (this.currentPage === this.circles) {
        return
      }
      // Если currentPage > 1 и в контейнер pagination НЕ помещаются точки - то sliderLine сдвинуть на 18px
      if (this.currentPage > 1 && this.$refs.pagination.scrollWidth < this.$refs.sliderLine.scrollWidth) {
        this.widthSliderline -= 18
      }
      this.currentPage += 1
      this.currentChange(this.currentPage)
    },
    prev () {
      if (this.currentPage > 1) {
        this.currentPage -= 1
        // Если currentPage НЕ первая страница и в контейнер pagination НЕ помещаются точки - то sliderLine сдвинуть на 18px
        if (this.currentPage !== 1 && this.$refs.pagination.scrollWidth < this.$refs.sliderLine.scrollWidth) {
          this.widthSliderline += 18
        }
      }
      this.currentChange(this.currentPage)
    },
    currentChange (page) {
      page--
      let usersCurrentPage = page * this.limit
      this.$emit('input', {
        name: 'offset',
        replicationGuid: this.$parent.block.pagination?.replicationGuid,
        value: usersCurrentPage
      })
    },
    getLoadParams (settings = {}) {
      const filters = this.getFilters(settings)

      let queryParams = null
      if (settings?.queryParameters) {
        settings.queryParameters.forEach(el => {
          let value
          if (el.type === 'field') value = this.getModel()[el.attribute]
          if (el.type === 'constant') value = el.attribute
          if (el.type === 'current_user') value = this.$store.getters['Authorization/userId']

          let formattedObj = { [el.alias]: value }
          queryParams = Object.assign(queryParams, formattedObj)
        })
      }

      const params = {
        offset: 0,
        id: { func: 'count' }
      }
      if (['Query', 'extended_object'].includes(this.settings.sourceType)) {
        let badBackend = this.queryParameters?.param || null
        params['query_parameters'] = { param: badBackend }
      }

      if (settings?.sourceType === 'Registry') {
        params['state_id'] = settings.stateId
      }

      if (filters.length) {
        params['where'] = { and: [...filters] }
      }

      if (queryParams) {
        params['query_parameters'] = queryParams
      }

      return params
    },
    getFilters (settings = {}) {
      const filters = []
      const replicationFilters = settings?.filters

      if (Array.isArray(replicationFilters)) {
        for (const filter of replicationFilters) {
          const object = {}
          // Ранее не было списка (equalsType) и применялся чекбокс (ссылка). Учитывать оба варианта, но список приоритетнее
          let equalsType = 'eq'
          switch (filter.equalsType /* Список */) {
            case 'eq': // Равно
              equalsType = (filter.isXref /* Чекбокс (ссылка) */) ? 'equals_any' : 'eq'
              break
            case 'eqx': // Ссылка
              equalsType = 'equals_any'
              break
            case 'se': // Поиск
              equalsType = 'like'
              break
          }
          this.$set(object, equalsType, {})

          if (!filter.alias) {
            return
          }

          const isXref = filter.isXref /* Чекбокс (ссылка) */ || (filter.equalsType /* Список */ === 'eqx' /* Ссылка */)
          const alias = isXref ? `${filter.alias}id` : filter.alias

          // Обработка типов фильтра
          if (!filter.type || filter.type === 'field') {
            const model = this.getModel()
            let value = null
            if (filter.attribute in model) {
              value = model[filter.attribute]
              if (value /* Фильтрация всех ложных условий, включая пустые строки и false */ && equalsType === 'like') {
                value = `%${value}%`
              }
            } else {
              // Простой компонент (атрибут) может не быть в model, если его значение не менялось
              console.warn('Не найден атрибут для фильтра пагинации в тиражировании ', filter.attribute)
            }

            if (value) {
              // Фильтрация всех ложных условий, включая пустые строки и false
              this.$set(object[equalsType], alias, value)
            }
          } else if (filter.type === 'constant') {
            let value = filter.attribute
            if (value /* Фильтрация всех ложных условий, включая пустые строки и false */ && equalsType === 'like') {
              value = `%${value}%`
            }
            this.$set(object[equalsType], alias, filter.attribute)
          } else if (filter.type === 'current_user') {
            this.$set(object[equalsType], alias, this.$store.getters['Authorization/userId'])
          }

          if (Object.keys(object[equalsType]).length > 0) {
            filters.push(object)
          }
        }
      }

      return filters
    },
    async getRegistryData (settings) {
      let params = this.getLoadParams(settings)
      let data = await this.$http.post(
        `${this.$config.api}/registryservice/registry/${settings.sourceId}`,
        params,
        {
          hideNotification: true
        }
      )
      return data.data
    },
    async getQueryDataCount (settings) {
      const params = this.getLoadParams(settings)

      let data = await this.$http.post(
        `${this.$config.api}/datawarehouseservice/query/${settings.sourceId}`,
        params,
        {
          hideNotification: true
        }
      )
      return data.data
    },
    async getExtendedObjectDataCount (settings) {
      const params = this.getLoadParams(settings)

      let data = await this.$http.post(
        `${this.$config.api}/datawarehouseservice/extended_object/${settings.sourceId}`,
        params,
        {
          hideNotification: true
        }
      )
      return data.data
    },
    // получить кол-во записей
    async setNumbersOfEntries (settings) {
      this.loading = true
      if (settings?.sourceId) {
        if (settings?.sourceType === 'Query') {
          const data = await this.getQueryDataCount(settings)
          this.total = data[0].count
        } else if (settings?.sourceType === 'extended_object') {
          const data = await this.getExtendedObjectDataCount(settings)
          this.total = data[0].count
        } else {
          const data = await this.getRegistryData(settings)
          this.total = data.data[0].count
        }
      }
      this.loading = false
    }
  },
  async mounted () {
    await this.setNumbersOfEntries(this.settings)

    this.$nextTick(() => {
      if (!this.$parent.block.pagination?.replicationGuid) return
      const blockGuid = this.$parent.block.pagination?.replicationGuid.replaceAll('-', '_')
      // guid блока связанный с пагинацией
      this.guid = blockGuid
      if (this.replicationFilter) {
        this.oldFilter = this.replicationFilter[blockGuid]?.where
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.pagination {
  .little-dot {
    width: 6px;
    height: 6px;
    background: #fff;
    border-radius: 100%;
    display: block;
    margin: 0 6px;
    position: relative;
    z-index: 10;
    flex: none;
    transition: all 0.5s;
    &.active {
      width: 16px;
      height: 16px;
      background-color: #0046cc;
    }
  }
  .pagination-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pagination-container {
    width: 100%;
    overflow: hidden;
  }
  .slider-line {
    display: flex;
    align-items: center;
    // justify-content: center;
    transition: all 0.5s;
  }
  .btn {
    cursor: pointer;
    width: 40px;
    transition: opacity 0.2s;
    z-index: 10;
  }
  .btn:hover {
    opacity: 0.6;
  }
}
</style>
